import { useContext, useRef, useState, } from "react";
import { AppContext, EnumAppEnvironment } from "sub/context/AppContext";
import { useLocation } from "react-router-dom";
import { MainContainer } from "./components/MainContainer";
import { Flex } from "./blocks/FlexBoxes";
import { ContentAddAnswer } from "./components/ContentAddAnswer";
import { TabContext, TabContextClass, TabDialogType, TabTableMode } from "./context/TabContext";
import { ListPollResults } from "./library/epBackendTypes";
import { PollDataClass } from "./bot-client-shared/PollDataClass";

export default function EntryAddAnswer() {

    // App context:
    const appContext = useContext(AppContext);
    appContext.environment.current = EnumAppEnvironment.message_chat;

    // Get location info:
    const locationInfo = useLocation();
    const searchParams = new URLSearchParams(locationInfo.search);
    const pollGuid = decodeURIComponent(searchParams.get("pollg") ?? "");
    const pollSecret = decodeURIComponent(searchParams.get("polls") ?? "");
    const messageId = decodeURIComponent(searchParams.get("message") ?? "");

    // Tab Context:
    // Used mainly for reusing polls.
    const [tabDialog, setTabDialog] = useState(TabDialogType.AddAnswer);

    const listPollResults = useRef<ListPollResults>(new ListPollResults());
    const cacheListPolls = useRef<ListPollResults | undefined>(undefined);
    const cacheListTemplates = useRef<ListPollResults | undefined>(undefined);


    const [tableMode, setTableMode] = useState(TabTableMode.modePolls);
    const tableSearch = useRef<string>("");
    const activePollElement = useRef<PollDataClass>(new PollDataClass({ poll_guid: pollGuid, poll_secret: pollSecret }));

    const [refreshTickerData, setRefreshTickerData] = useState(1);
    const [refreshTickerGUI, setRefreshTickerGUI] = useState(1);

    const tabContext: TabContextClass = {
        tabDialog: tabDialog,
        setTabDialog: setTabDialog,

        listPollResults: listPollResults,
        cacheListPolls: cacheListPolls,
        cacheListTemplates: cacheListTemplates,

        tableMode: tableMode,
        setTableMode: setTableMode,
        tableSearch: tableSearch,
        activePollElement: activePollElement,

        refreshTickerData: refreshTickerData,
        setRefreshTickerData: setRefreshTickerData,
        refreshTickerGUI: refreshTickerGUI,
        setRefreshTickerGUI: setRefreshTickerGUI,
    };

    return (
        <TabContext.Provider value={tabContext}>
            <MainContainer botDialog>
                <Flex column fillFlex gap="smaller" padding="medium">
                    <ContentAddAnswer messageId={messageId} />
                </Flex>
            </MainContainer>
        </TabContext.Provider>
    )




}


// environment is a global variable that is set to true in development and false in production
// import differs between client and bot.

import { environment } from "../../library/epCommon";
// import { environment } from "../../library/botCommon";

import { CreateEditVoteResults, CreateEditVoteWarnings, CreatePollResults, EditPollWarnings } from "../../library/epBackendTypes";
// import { CreateEditVoteResults, CreateEditVoteWarnings, CreatePollResults, EditPollWarnings } from "../../library/botBackendTypes";

// ------------------ identical shared code ------------------

import * as ACData from "adaptivecards-templating";

import templateCardPoll from "./templatePoll.json";
import templateCardPollResult from "./templatePollResult.json";
import templateCardPollResultThankYou from "./templatePollResultThankYou.json";
import templatePollResultRestricted from "./templatePollResultRestricted.json";
import templateCardBigPoll from "./templateBigPoll.json";
import templateCardStandardText from "./templateStandardText.json";
import templateDialogInstallBot from "./templateDialogInstallBot.json";
import templateDialogStartConversation from "./templateDialogStartConversation.json";

import { EnumPolLView, PollDataClass } from "../PollDataClass";
import { hyDate, transformDateToDayMonthYear, link_teams_app } from "../../library/epCommon";

export class Warning extends Error { };
export class UserError extends Error { };

export const cardStatus200Card = "application/vnd.microsoft.card.adaptive";
export const cardStatus200Message = "application/vnd.microsoft.activity.message";

export const actionPollUnlockResults = "pollUnlockResults";
export const actionPollSubmitVote = "pollSubmitVote";

export const actionResultsCardRefreshAuto = "personalResultsCardRefresh";
export const actionResultsCardRefreshUser = "personalResultsCardRefreshUser";
export const actionResultsCardRefreshUser2 = "personalResultsCardRefreshUser2";
export const actionResultsCardEditVote = "personalResultsEditVote";

export const actionShowResultDetails = "showResultDetails";

export const actionGlobalPollCardRefreshAuto = "globalPollCardRefresh";
export const actionGlobalPollCardRefreshUser = "globalPollCardRefreshUser";

export const actionGlobalOpenPoll = "globalOpenPoll";
export const actionGlobalClosePoll = "globalClosePoll";

export const actionGlobalRemovePoll = "globalRemovePoll";
export const actionGlobalRemoveCard = "globalRemoveCard";

export const cdn_baseurl = (environment === 'local') ? "https://cdn.easy-poll.app/local" : "https://cdn.easy-poll.app/app"

export enum EnumCardEnvironment {
    bot,
    client
}

// Technically the max is 40kb for Teams chats, but those cards are way too big to display. So we reduce it.
export const BotMaxCardSize = Math.floor(0.75 * 40000);

function getPollDetailsText(fullDetails: boolean, locale: string, creator: string, created_on: string, anonymous: boolean, is_closed: boolean): string {
    const text_anonymous = (anonymous) ? "Anonymous" : "Non-anonymous";
    const text_closed = (is_closed) ? "Closed" : "Open";

    const basic_text = text_closed + " | " + text_anonymous;

    if (fullDetails) {
        return "Created by " + creator + " on " + transformDateToDayMonthYear(locale, hyDate(created_on)) + " | " + basic_text;
    } else {
        return basic_text;
    }
}

function getSubmitDetailsText(have_limit: boolean, max_limit: number): string {
    if (!have_limit || max_limit === 1) { return ""; } // Return space to avoid mobile card warning (warning: text is empty).
    return "You can submit max. " + max_limit + " answers per vote";
}


function getVoteNumberText(curValue: number, maxValue: number): string {
    const percent = _getVoteNumberPercent(curValue, maxValue);
    return percent + "% (" + curValue + ")";
}

function getVoteNumberImage(curValue: number, maxValue: number): string {
    const percent = _getVoteNumberPercent(curValue, maxValue);
    let imagePath = "";

    if (percent < 0) { imagePath = cdn_baseurl + "/cards/votes/percent_0.png"; }
    if (percent > 100) { imagePath = cdn_baseurl + "/cards/votes/percent_100.png"; }
    imagePath = cdn_baseurl + "/cards/votes/percent_" + percent + ".png";

    return imagePath;
}

function _getVoteNumberPercent(curValue: number, maxValue: number): number {
    if (maxValue <= 0) { return 0; }
    return Math.round((curValue / maxValue) * 100);
}

function getOpenClosePollProps(is_creater: boolean, is_closed: boolean): any {
    return {
        enabledOpenClosePoll: is_creater,
        iconOpenClosePoll: (is_closed) ? (cdn_baseurl + "/cards/iconLockOpen.png") : (cdn_baseurl + "/cards/iconLockClosed.png"),
        textOpenClosePoll: (is_closed) ? "Open poll" : "Close poll",
        idOpenClosePoll: getOpenClosePollId(is_closed)
    }
}

function getOpenClosePollId(is_closed: boolean): string {
    return (is_closed) ? actionGlobalOpenPoll : actionGlobalClosePoll;
}

function getCommentText(commentTotal: number): string {
    if (commentTotal === 0) { return ""; }
    if (commentTotal === 1) { return "The poll has 1 comment."; }
    return "The poll has " + commentTotal + " comments.";
}

export class CardBuilderReturnInfos {
    public isBigPoll: boolean = false;

    constructor(data?: any) {
        if (data) {
            this.isBigPoll = data.isBigPoll ?? this.isBigPoll;
        }
    }
}

// Remark on cardBuilderReturnInfos: As this function returns any, we need a way to return additional information. This is done by using the cardBuilderReturnInfos object.
// If we would change the return type to a specific type, there is a high risk of mixing up the return values. Maybe this will change in the future.
export function createCardFromPollData(cardEnvironment: EnumCardEnvironment, refreshUserMRI: string | null, pollData: PollDataClass, locale: string, cardBuilderReturnInfos?: CardBuilderReturnInfos): any {

    // Card expires after 30 seconds or 5 mins. Team will update card once 
    const refreshTime = new Date();
    refreshTime.setUTCSeconds(refreshTime.getUTCSeconds() + (pollData.hot_reload ? 30 : (5 * 60)));

    const withFullDetails = (pollData.poll_view === EnumPolLView.result_details);

    // Case 1: Poll
    // Case 2: Results
    //      2a: Results (normal)
    //      2b: Results (with restriction)
    // Case 3: Results Thank You
    // Case 4: Poll not found
    // Case 5: Big Poll (never reached, see details)
    // Case 6: Unknown

    if (pollData.poll_view === EnumPolLView.poll) {
        // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        // Show Poll
        // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

        // Filter, map, and join operations
        const defaultValue = pollData.answers
            .filter(item => item.pre_select)  // Filter to get items where pre_select is true
            .map(item => item.answer_guid)           // Map to retrieve the guid of each item
            .join(',');

        const submitDetailsText = getSubmitDetailsText(pollData.option_votes_have_limit, pollData.option_votes_max_limit);

        // Create Poll View
        const templatePoll = new ACData.Template(templateCardPoll);
        const cardPoll = templatePoll.expand({
            $root: {
                // Refresh data
                refreshUserMRIs: refreshUserMRI ? [refreshUserMRI] : [],
                refreshExpiration: refreshTime.toISOString(),

                // Submenu (poll)
                iconOpenApp: cdn_baseurl + "/cards/iconApp.png",
                iconRefresh: cdn_baseurl + "/cards/iconRefresh.png",
                iconEditPoll: cdn_baseurl + "/cards/iconEditPoll.png",
                ...getOpenClosePollProps(pollData.user_is_creator, pollData.poll_is_closed),
                iconRemovePoll: cdn_baseurl + "/cards/iconRemovePoll.png",

                // Header
                pollDetailsText: getPollDetailsText(withFullDetails, locale, pollData.created_by, pollData.created_on, pollData.option_votes_are_anonymous, pollData.poll_is_closed),

                // Question and Answers
                question: pollData.question,
                defaultValue: defaultValue,
                multiSelectAllowed: (!pollData.option_votes_have_limit || pollData.option_votes_max_limit > 1),
                answers: pollData.answers.map((answer) => ({
                    title: answer.text, // Using the 'text' property of each answer as the title
                    value: answer.answer_guid
                })),
                // Comment section
                commentPlaceholder: (pollData.answers.length === 0) ? "(required)" : "(optional)",
                preSelectComment: (pollData.option_voters_add_comments && pollData.pre_selected_comment) ? pollData.pre_selected_comment : "",

                // Footer
                enabledAddAnswer: pollData.option_voters_add_answers, // Fallback before deleting the property.
                submitDetailsText: submitDetailsText,

                // Action Data
                pollGuid: pollData.poll_guid,
                pollSecret: pollData.poll_secret,
            },
        });

        // Remove comment field if not allowed
        if (!pollData.option_voters_add_comments) {
            removeItemFromCard(cardPoll, RemoveItemCategoryEnum.body, "comment");
        }

        // Remove empty submit details text (better usabaility in Tab)
        if (!submitDetailsText) {
            removeItemFromCard(cardPoll, RemoveItemCategoryEnum.body, "submitDetailsContainer");
        }

        if (!pollData.user_is_creator) {
            // Remove edit poll
            removeItemFromCard(cardPoll, RemoveItemCategoryEnum.hardcoded_headerActions, "globalEditPoll");
            removeItemFromCard(cardPoll, RemoveItemCategoryEnum.hardcoded_headerActions, getOpenClosePollId(pollData.poll_is_closed));
        }

        // Remove add answer action if not allowed
        if (!pollData.option_voters_add_answers) {
            removeItemFromCard(cardPoll, RemoveItemCategoryEnum.actions, "pollAddAnswer");
        }

        // Check card size (bot only)
        // If true, return a standard big poll card with links to the app for voting / results.
        if (_isPollTooBigForChat(cardEnvironment, cardPoll)) {
            // Set additional return information.
            if (cardBuilderReturnInfos) { cardBuilderReturnInfos.isBigPoll = true; }
            return _createBigPollCard(pollData);
        }

        return cardPoll;
    } else if (pollData.poll_view === EnumPolLView.result || pollData.poll_view === EnumPolLView.result_details) {
        let cardResult: any;

        if (!pollData.poll_results_restricted) {
            // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
            // Show results (without restriction)
            // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
            const templateResult = new ACData.Template(templateCardPollResult);
            cardResult = templateResult.expand({
                $root: {
                    // Refresh data
                    refreshUserMRIs: refreshUserMRI ? [refreshUserMRI] : [],
                    refreshExpiration: refreshTime.toISOString(),

                    // Submenu (results)
                    iconOpenApp: cdn_baseurl + "/cards/iconApp.png",
                    iconRefresh: cdn_baseurl + "/cards/iconRefresh.png",
                    iconShowDetails: cdn_baseurl + "/cards/iconShowDetails.png",
                    iconUpdateVote: cdn_baseurl + "/cards/iconUpdateVote.png",
                    ...getOpenClosePollProps(pollData.user_is_creator, pollData.poll_is_closed),
                    iconRemovePoll: cdn_baseurl + "/cards/iconRemovePoll.png",

                    // Header
                    resultHeaderSuffix: (pollData.poll_view === EnumPolLView.result_details) ? " Details" : "",
                    pollDetailsText: getPollDetailsText(withFullDetails, locale, pollData.created_by, pollData.created_on, pollData.option_votes_are_anonymous, pollData.poll_is_closed),

                    // Question
                    question: pollData.question,

                    // Card specific content
                    answers: pollData.answers.map((answer) => ({
                        text: answer.text,
                        imageUrl: getVoteNumberImage(answer.answer_votes, pollData.poll_votes_total),
                        voteText: getVoteNumberText(answer.answer_votes, pollData.poll_votes_total),
                        voteNames: answer.voter_names
                    })),

                    // Footer
                    totalCommentsText: getCommentText(pollData.poll_comments_total),
                    totalVotesText: getVoteNumberText(pollData.poll_votes_total, pollData.poll_votes_total),
                    showIsClosedText: (pollData.poll_is_closed),

                    // Action Data
                    pollGuid: pollData.poll_guid,
                    pollSecret: pollData.poll_secret,

                },
            });

        } else if (pollData.poll_results_restricted) {
            // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
            // Show results (WITH restriction)
            // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

            const templateResult = new ACData.Template(templatePollResultRestricted);
            cardResult = templateResult.expand({
                $root: {
                    // Refresh data
                    refreshUserMRIs: refreshUserMRI ? [refreshUserMRI] : [],
                    refreshExpiration: refreshTime.toISOString(),

                    // Submenu (results)
                    iconOpenApp: cdn_baseurl + "/cards/iconApp.png",
                    iconRefresh: cdn_baseurl + "/cards/iconRefresh.png",
                    iconShowDetails: cdn_baseurl + "/cards/iconShowDetails.png",
                    iconUpdateVote: cdn_baseurl + "/cards/iconUpdateVote.png",
                    ...getOpenClosePollProps(pollData.user_is_creator, pollData.poll_is_closed),
                    iconRemovePoll: cdn_baseurl + "/cards/iconRemovePoll.png",

                    // Header
                    resultHeaderSuffix: (pollData.poll_view === EnumPolLView.result_details) ? " Details" : "",
                    pollDetailsText: getPollDetailsText(withFullDetails, locale, pollData.created_by, pollData.created_on, pollData.option_votes_are_anonymous, pollData.poll_is_closed),

                    // Question
                    question: pollData.question,

                    // Card specific content
                    iconEnterKey: cdn_baseurl + "/cards/iconEnterKey.png",

                    // Footer
                    totalCommentsText: getCommentText(pollData.poll_comments_total),
                    totalVotesText: getVoteNumberText(pollData.poll_votes_total, pollData.poll_votes_total),
                    showIsClosedText: (pollData.poll_is_closed),

                    // Action Data
                    pollGuid: pollData.poll_guid,
                    pollSecret: pollData.poll_secret,
                },
            });
        }

        // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        // Remove certain elements if they are not needed
        // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++

        if (pollData.poll_comments_total === 0) {
            // Search for body item with id "totalComments" and remove it.
            removeItemFromCard(cardResult, RemoveItemCategoryEnum.body, "totalComments");
        }

        if (pollData.answers.length === 0) {
            // Search for body item with id "totalVotesContainer" and remove it.
            removeItemFromCard(cardResult, RemoveItemCategoryEnum.body, "totalVotesContainer");
        }

        if (!pollData.user_is_creator) {
            removeItemFromCard(cardResult, RemoveItemCategoryEnum.hardcoded_headerActions, getOpenClosePollId(pollData.poll_is_closed));
        }

        // Check card size (bot only)
        // If true, return a standard big poll card with links to the app for voting / results.
        if (_isPollTooBigForChat(cardEnvironment, cardResult)) {
            // Set additional return information.
            if (cardBuilderReturnInfos) { cardBuilderReturnInfos.isBigPoll = true; }
            return _createBigPollCard(pollData);
        }


        // Return card result with result card (with or without restriction)
        return cardResult;

    } else if (pollData.poll_view === EnumPolLView.result_thankyou) {
        // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        // Thank you card (Results for creator only)
        // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

        const templateResult = new ACData.Template(templateCardPollResultThankYou);
        const cardResult = templateResult.expand({
            $root: {
                // Refresh data
                refreshUserMRIs: refreshUserMRI ? [refreshUserMRI] : [],
                refreshExpiration: refreshTime.toISOString(),

                // Submenu (results)
                iconOpenApp: cdn_baseurl + "/cards/iconApp.png",
                iconRefresh: cdn_baseurl + "/cards/iconRefresh.png",
                iconShowDetails: cdn_baseurl + "/cards/iconShowDetails.png",
                iconUpdateVote: cdn_baseurl + "/cards/iconUpdateVote.png",
                ...getOpenClosePollProps(pollData.user_is_creator, pollData.poll_is_closed),
                iconRemovePoll: cdn_baseurl + "/cards/iconRemovePoll.png",

                // Question
                question: pollData.question,

                // Footer
                showIsClosedText: (pollData.poll_is_closed),

                // Action Data
                pollGuid: pollData.poll_guid,
                pollSecret: pollData.poll_secret

            },
        });

        // No need to check for big poll here. The thank you card is small enough.

        return cardResult;

    } else if (pollData.poll_view === EnumPolLView.not_found) {
        // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        // Poll not found
        // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

        const cardResult = createStandardCard("Poll not found", "The poll you are looking for does not exist.", "", "");
        return cardResult;

    } else if (pollData.poll_view === EnumPolLView.bot_only_big_poll) {
        // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        // Big Poll
        // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

        // The big poll is created by _createBigPollCard() function.
        // This is just a reminder that this function also needs to be keep up to date.
        // This case should never be reached.

        throw new Error('NotImplementedHere');
    } else {
        throw new Error("Poll view not known. Please contact support at support@easy-poll.app.");
    }
}

function _createBigPollCard(pollData: PollDataClass): any {
    // Not really necessary.
    pollData.poll_view = EnumPolLView.bot_only_big_poll;

    const templateResult = new ACData.Template(templateCardBigPoll);
    const cardBigPoll = templateResult.expand({
        $root: {
            // Refresh data
            // Not used

            // Submenu (results)
            iconOpenApp: cdn_baseurl + "/cards/iconApp.png",
            iconRefresh: cdn_baseurl + "/cards/iconRefresh.png",
            iconRemovePoll: cdn_baseurl + "/cards/iconRemovePoll.png",

            // Header
            pollDetailsText: "Large poll",

            // Question
            question: pollData.question,


            // Footer
            // None shown. The card does not refresh itself automatically, so we would only show false data.

            // Action Data
            pollGuid: pollData.poll_guid,
            pollSecret: pollData.poll_secret,

            urlVote: _getDeepLinkVote(pollData.poll_guid, pollData.poll_secret),
            urlResults: _getDeepLinkResults(pollData.poll_guid, pollData.poll_secret),
        },
    });

    return cardBigPoll;
}


function _isPollTooBigForChat(cardEnvironment: EnumCardEnvironment, cardPoll: any): boolean {
    if (cardEnvironment === EnumCardEnvironment.client) { return false; }

    // console.time("cardSizeCalculation");
    const cardPollString = JSON.stringify(cardPoll);
    const cardPollBytes = Buffer.byteLength(cardPollString, "utf-8");
    // console.timeEnd("cardSizeCalculation");

    // Note: For big cards, this operation takes ~ 0.181ms. This is acceptable.

    return (cardPollBytes > BotMaxCardSize);
}

function _getDeepLinkVote(poll_guid: string, poll_secret: string): string {
    const encodedContext = encodeURI('{"subEntityId": "vote_' + poll_guid + '_' + poll_secret + '"}');
    const deeplink = link_teams_app + '/index?&context=' + encodedContext;

    return deeplink;
}

function _getDeepLinkResults(poll_guid: string, poll_secret: string): string {
    const encodedContext = encodeURI('{"subEntityId": "results_' + poll_guid + '_' + poll_secret + '"}');
    const deeplink = link_teams_app + '/index?&context=' + encodedContext;

    return deeplink;
}

export function createStandardCard(title: string, text: string, user: string, error: string): any {

    // The user has chosen to create a card by choosing the 'Create Card' context menu command.
    const template = new ACData.Template(templateCardStandardText);
    const card = template.expand({
        $root: {
            title: title,
            text: text,
            user: user,
            error: error
        },
    });

    return card;
}

export function createDialogInstallBotCard(): any {

    const template = new ACData.Template(templateDialogInstallBot);
    return template.expand({ $root: {} });
}

export function createDialogStartConversationCard(): any {

    const template = new ACData.Template(templateDialogStartConversation);
    return template.expand({ $root: {} });
}


export interface AnyObject {
    [key: string]: any;
}

export function cleanCardFromActions(obj: AnyObject, cleanBottomAction: boolean): AnyObject {
    // Helper function to recursively process the object
    const recurse = (item: AnyObject, cleanBottomAction: boolean): AnyObject => {
        if (item && typeof item === 'object') {
            // Iterate over each property in the object
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    const value = item[key];

                    // If the value is an object and has a type of "ActionSet", delete it
                    if (value && typeof value === 'object' && value.type === 'ActionSet') {
                        delete item[key];
                    } else if (cleanBottomAction && key === 'actions') {
                        // If the key is "actions", delete the key and its content
                        delete item[key];
                    } else if (key === 'refresh') {
                        // If the key is "refresh", delete the key and its content
                        delete item[key];
                    } else if (value && typeof value === 'object') {
                        // Recursively process nested objects
                        item[key] = recurse(value, cleanBottomAction);
                    }
                }
            }
        }
        return item;
    };

    // Function to clean up null values from arrays
    const cleanUpArrays = (item: AnyObject): AnyObject => {
        if (item && typeof item === 'object') {
            if (Array.isArray(item)) {
                // Filter out null values from arrays
                return item.map(cleanUpArrays).filter(element => element !== null);
            } else {
                // Recursively clean arrays in nested objects
                for (const key in item) {
                    if (item.hasOwnProperty(key)) {
                        item[key] = cleanUpArrays(item[key]);
                    }
                }
            }
        }
        return item;
    };

    // Clone the original object to avoid mutating it
    const clonedObj = JSON.parse(JSON.stringify(obj));

    // Process the cloned object for ActionSet and refresh removal
    const cleanedObj = recurse(clonedObj, cleanBottomAction);

    // Perform a final cleanup to remove nulls from arrays
    return cleanUpArrays(cleanedObj);
}

enum RemoveItemCategoryEnum {
    body,
    hardcoded_headerActions,
    actions
}

function removeItemFromCard(card: any, removeCategory: RemoveItemCategoryEnum, id: string): void {
    try {
        if (removeCategory === RemoveItemCategoryEnum.hardcoded_headerActions) {
            // Hardcoded search: Warning: If poll structure changes, this function needs to be updated.
            if (card && card.body[0].columns[1].items[0].actions.length > 0) {
                // Search field and remove it.
                const index = card.body[0].columns[1].items[0].actions.findIndex((element: any) => element.id === id);
                if (index >= 0) { card.body[0].columns[1].items[0].actions.splice(index, 1); }
                return;
            }

        } else if (removeCategory === RemoveItemCategoryEnum.body) {
            // Top-Level Search only
            if (card && card.body && card.body.length > 0) {
                // Search field and remove it.
                const index = card.body.findIndex((element: any) => element.id === id);
                if (index >= 0) { card.body.splice(index, 1); }
                return;
            }
        } else if (removeCategory === RemoveItemCategoryEnum.actions) {
            // Top-Level Search only
            if (card && card.actions && card.actions.length > 0) {
                // Search field and remove it.
                const index = card.actions.findIndex((element: any) => element.id === id);
                if (index >= 0) { card.actions.splice(index, 1); }
                return;
            }
        }
    } catch (ex: any) {
        // Use generic console so code works for bot and client.
        console.error("Error while removing item from card: " + ex?.message);
    }
}



export function checkEditVoteResponse(voteResult: CreateEditVoteResults, action_id: string): void {
    if (!voteResult.success) {

        if (voteResult.code === CreateEditVoteWarnings.limit_violation) {
            throw new Warning("Vote not submitted: Please don't select more than " + voteResult.pollData.option_votes_max_limit + " answers.");
        }
        else if (voteResult.code === CreateEditVoteWarnings.no_answer_selected || voteResult.code === CreateEditVoteWarnings.no_comment_provided) {
            throw new Warning("Vote not submitted: Please provide at least 1 answer.");
        }
        else if (voteResult.code === CreateEditVoteWarnings.poll_is_closed) {
            if (action_id === actionResultsCardEditVote) {
                throw new Warning("The poll is already closed. Your vote can not be edited.");
            } else if (action_id === actionPollSubmitVote) {
                // Do nothing, card with results will be shown.
            }

        } else if (voteResult.code === CreateEditVoteWarnings.poll_not_found) {
            // Do nothing, card with error message will be shown.
        } else {
            throw new Error((voteResult.warning) ? "Error: " + voteResult.warning : "Unknown error (210).");
        }
    }
}


export function checkEditPollResponse(editPolLResult: CreatePollResults): void {

    if (!editPolLResult.success) {
        if (editPolLResult.code === EditPollWarnings.user_is_not_creator) {
            throw new Warning("This action can only be performed by the poll creator. Did you select the right account in Teams?");
        } else if (editPolLResult.code === EditPollWarnings.unlock_poll_already_used) {
            throw new Warning("The creator has already unlocked a poll this month.");
        } else if (editPolLResult.code === EditPollWarnings.answers_limit_reached) {
            throw new Warning("The maximum number of answers has been reached.");
        } else if (editPolLResult.code === EditPollWarnings.poll_not_found) {
            throw new Warning("The poll you are looking for does not exist.");
        } else if (editPolLResult.code === EditPollWarnings.invalid_add_answer) {
            throw new Warning("Adding custom answers has been deactivated on this poll.");
        } else {
            throw new Error((editPolLResult.warning) ? "Error: " + editPolLResult.warning : "Unknown error (211).");
        }
    }
}


import { Button, Spinner, Text, } from "@fluentui/react-components";
import { Flex, FlexItem } from "./blocks/FlexBoxes";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext, EnumAppEnvironment } from "sub/context/AppContext";
import { MainContainer } from "./components/MainContainer";
import { useLocation } from "react-router-dom";
import { PollDataClass } from "./bot-client-shared/PollDataClass";
import { dialog as teamsDialog } from "@microsoft/teams-js";
import { ContentViewPoll } from "./components/ContentViewPoll";
import { TabContext, TabContextClass, TabDialogType, TabTableMode } from "./context/TabContext";
import { ListPollResults } from "./library/epBackendTypes";

export default function EntryPollResults() {

    // App context:
    const appContext = useContext(AppContext);
    appContext.environment.current = EnumAppEnvironment.message_chat;

    // Get location info:
    const locationInfo = useLocation();
    const searchParams = new URLSearchParams(locationInfo.search);
    const pollGuid = decodeURIComponent(searchParams.get("pollg") ?? "");
    const pollSecret = decodeURIComponent(searchParams.get("polls") ?? "");

    // Tab Context:
    // Used mainly for reusing polls.
    const [tabDialog, setTabDialog] = useState(TabDialogType.ViewOrPostPoll);

    const listPollResults = useRef<ListPollResults>(new ListPollResults());
    const cacheListPolls = useRef<ListPollResults | undefined>(undefined);
    const cacheListTemplates = useRef<ListPollResults | undefined>(undefined);


    const [tableMode, setTableMode] = useState(TabTableMode.modePolls);
    const tableSearch = useRef<string>("");
    const activePollElement = useRef<PollDataClass>(new PollDataClass({poll_guid : pollGuid, poll_secret : pollSecret}));

    const [refreshTickerData, setRefreshTickerData] = useState(1);
    const [refreshTickerGUI, setRefreshTickerGUI] = useState(1);

    const tabContext: TabContextClass = {
        tabDialog: tabDialog,
        setTabDialog: setTabDialog,

        listPollResults: listPollResults,
        cacheListPolls: cacheListPolls,
        cacheListTemplates: cacheListTemplates,

        tableMode: tableMode,
        setTableMode: setTableMode,
        tableSearch: tableSearch,
        activePollElement: activePollElement,

        refreshTickerData: refreshTickerData,
        setRefreshTickerData: setRefreshTickerData,
        refreshTickerGUI: refreshTickerGUI,
        setRefreshTickerGUI: setRefreshTickerGUI,
    };

    // Check for changing dialog workflow
    useEffect(() => {
        if (tabContext.tabDialog === TabDialogType.None) {
            __onClose();
        }
    }, [tabContext.tabDialog]);

    return (
        <TabContext.Provider value={tabContext}>
            <MainContainer botDialog>
                <Flex column fillFlex gap="small" padding="medium">
                    <ContentViewPoll />
                </Flex>
            </MainContainer >
        </TabContext.Provider>
    );



    function __onClose() {

        // Submit will automatically close the dialog
        teamsDialog.url.submit();
    }
}


import { Dialog, Button, DialogSurface, DialogTitle, DialogContent, Text, DialogActions, Image, DialogBody, Checkbox } from "@fluentui/react-components";
import { useContext } from "react";
import { Dismiss24Regular } from "@fluentui/react-icons";

import { EmptyFlexRow, Flex, FlexItem } from "sub/blocks/FlexBoxes";
import { TabContext, TabDialogType } from "sub/context/TabContext";
import { PollHorizontalRegular } from "@fluentui/react-icons";
import { dialog_close_refresh } from "sub/library/epCommon";
import { AppContext } from "sub/context/AppContext";
import { SettingsStorage } from "sub/library/epViewSettings";

export function TabDialogGetStarted() {
    const appContext = useContext(AppContext);
    const tabContext = useContext(TabContext);

    return (
        <Dialog defaultOpen onOpenChange={(event, data) => { if (!data.open) { dialog_close_refresh(tabContext); } }}>
            <DialogSurface>
                <DialogBody >
                    <DialogTitle action={<Button appearance="subtle" aria-label="close" onClick={() => { dialog_close_refresh(tabContext); }} icon={<Dismiss24Regular />} />}>Get Started</DialogTitle>

                    <DialogContent>
                        <Flex column fillFlex gap="small" padding="medium" alignItems="center" justifyContent="center" style={{ overflow: 'auto' }}>
                            <Text wrap>Go to any chat, group chat or teams channel and click on '+' to add the Easy Poll app and create a poll. You can create a poll or resuse an existing poll in any chat.</Text>
                            <EmptyFlexRow size="1.0" />
                            <Image src="easypoll_getstarted.png" style={{ maxWidth: "80%" }} />
                            <EmptyFlexRow size="1.0" />
                            <Text wrap>You can also create a draft here and prepare a voting. Reuse it later in the place you would like to submit the poll.</Text>
                        </Flex>
                    </DialogContent>
                    <DialogActions>
                        <Checkbox label="Don't show again" checked={appContext.viewSettings.storage.hideGetStartedOnStartup} onClick={() => { _toogleHideGetStartedOnStartup(); }}></Checkbox>
                        <Button appearance="secondary" onClick={() => { dialog_close_refresh(tabContext); }}>Close</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>

    );

    function _toogleHideGetStartedOnStartup() {
        const newVal = !appContext.viewSettings.storage.hideGetStartedOnStartup;

        appContext.viewSettings.storage.hideGetStartedOnStartup = newVal;
        appContext.updateViewSettings({ storage: appContext.viewSettings.storage })
        SettingsStorage.WriteToStorage(appContext.viewSettings.storage);
    }

}
import React from "react";
import { ViewSettings } from "sub/library/epViewSettings";
import { AppAuthorizationData } from "sub/library/useAppAuthorization";

export enum EnumAppEnvironment {
    not_set,
    message_chat,
    personal_tab
}


export class AppContextClass {
    public environment!: React.MutableRefObject<EnumAppEnvironment>;

    public appAuthData: AppAuthorizationData = new AppAuthorizationData();
    public appDeepLink!: React.MutableRefObject<string>;

    public setAuthRefreshTicker: (param: number | ((param: number) => number)) => void = () => undefined;

    public isSSOEnabled: boolean = false;
    public setIsSSOEnabled: (param: boolean) => void = () => undefined;

    public themeString: string = "default";

    public viewSettings: ViewSettings = new ViewSettings();
    public updateViewSettings: (updates: Partial<ViewSettings>) => void = () => undefined;
}

export const AppContext = React.createContext<AppContextClass>(new AppContextClass());

export function getAppEnvironment(environment: EnumAppEnvironment): string {
    return (environment === EnumAppEnvironment.personal_tab ? "tab" : "chat");
}
import { Link, Text } from "@fluentui/react-components";
import { Flex } from "../blocks/FlexBoxes";
import { useContext, useEffect } from "react";
import { AppContext, getAppEnvironment } from "sub/context/AppContext";
import { backendTrackEvent } from "sub/library/epBackend";
import { EnumPollWorkflow, PollContext } from "sub/context/PollContext";

// PollContext:
// This component is embedded in the pollworkflow
export function PollErrorLoading() {

    const appContext = useContext(AppContext);
    const pollContext = useContext(PollContext);

    useEffect(() => {
        // Track events
        const category = getAppEnvironment(appContext.environment.current);
        backendTrackEvent(appContext.appAuthData.lang, category, "Poll.ErrorLoading", "Component. Workflow: " + pollContext.pollWorkflow.toString(), appContext);
    }, []);

    let message = (<></>);

    switch (pollContext.pollWorkflow) {
        case EnumPollWorkflow.loading_not_found:
            message = (
                <>
                    <Text wrap><b>The poll was not found.</b></Text>
                    <Text wrap>If you think this is not correct, please contact support at <Link inline href="mailto:support@easy-poll.app">support@easy-poll.app</Link>.</Text>
                </>
            );
            break;

        case EnumPollWorkflow.loading_no_creator:
            message = (
                <>
                    <Text wrap><b>The poll can only be edited by the poll creator.</b> Did you select the right account in Teams?</Text>
                    <Text wrap>If you think this is not correct, please contact support at <Link inline href="mailto:support@easy-poll.app">support@easy-poll.app</Link>.</Text>
                </>
            );
            break;

        case EnumPollWorkflow.loading_other_error:
            message = (
                <>
                <Text wrap>Oh Oh. Something went wrong.</Text>
                <Text wrap>Please try again later or contact support at <Link inline href="mailto:support@easy-poll.app">support@easy-poll.app</Link>.</Text>
                </>
            );
    }

    // Actual component
    return (
        <Flex column fillHeight gap="small" alignItems="center" justifyContent="center" style={{ overflow: 'auto' }}>
            {message}
        </Flex>
    );
}

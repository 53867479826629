import { Button, Spinner, Title3, tokens } from "@fluentui/react-components";
import { Flex, FlexItem } from "./blocks/FlexBoxes";
import { PollContextClass, PollContext, EnumPollWorkflow } from "./context/PollContext";
import { SaveSearchRegular, PollHorizontalRegular, DocumentRegular } from "@fluentui/react-icons";
import { useContext, useEffect, useRef, useState } from "react";
import { PollCreateOrEdit } from "./components/PollCreateOrEdit";
import { AppContext, EnumAppEnvironment } from "sub/context/AppContext";
import { PollPreview } from "./components/PollPreview";
import { MainContainer } from "./components/MainContainer";
import { PollDataClass } from "./bot-client-shared/PollDataClass";
import { TabContext, TabContextClass, TabDialogType, TabTableMode } from "./context/TabContext";
import { ListPollResults } from "./library/epBackendTypes";
import { getPollDataToEditPollEntry } from "./library/epCommon";
import { useLocation } from "react-router-dom";
import { PollErrorLoading } from "./components/PollErrorLoading";

export default function EntryEditPoll() {

    // App context:
    const appContext = useContext(AppContext);
    appContext.environment.current = EnumAppEnvironment.message_chat;

    // Get location info:
    const locationInfo = useLocation();
    const searchParams = new URLSearchParams(locationInfo.search);
    const pollGuid = decodeURIComponent(searchParams.get("pollg") ?? "");
    const pollSecret = decodeURIComponent(searchParams.get("polls") ?? "");
    const messageId = decodeURIComponent(searchParams.get("message") ?? "");

    // Poll context:
    const [pollWorkflow, setPollWorkflow] = useState(EnumPollWorkflow.editPoll_loading);

    const [contextPollData, setContextPollData] = useState(new PollDataClass());
    const updatePollData = (updates: Partial<PollDataClass>) => {
        setContextPollData(current => ({ ...current, ...updates }));
    };

    const pollContext: PollContextClass =
    {
        pollWorkflow: pollWorkflow,
        setPollWorkflow: setPollWorkflow,

        pollData: contextPollData,
        updatePollData: updatePollData,
    };

    // Tab Context:
    const [tabDialog, setTabDialog] = useState(TabDialogType.None);

    const listPollResults = useRef<ListPollResults>(new ListPollResults());
    const cacheListPolls = useRef<ListPollResults | undefined>(undefined);
    const cacheListTemplates = useRef<ListPollResults | undefined>(undefined);


    const [tableMode, setTableMode] = useState(TabTableMode.modePolls);
    const tableSearch = useRef<string>("");
    const activePollElement = useRef<PollDataClass>(new PollDataClass({ poll_guid: pollGuid, poll_secret: pollSecret }));

    const [refreshTickerData, setRefreshTickerData] = useState(1);
    const [refreshTickerGUI, setRefreshTickerGUI] = useState(1);

    const tabContext: TabContextClass = {
        tabDialog: tabDialog,
        setTabDialog: setTabDialog,

        listPollResults: listPollResults,
        cacheListPolls: cacheListPolls,
        cacheListTemplates: cacheListTemplates,

        tableMode: tableMode,
        setTableMode: setTableMode,
        tableSearch: tableSearch,
        activePollElement: activePollElement,

        refreshTickerData: refreshTickerData,
        setRefreshTickerData: setRefreshTickerData,
        refreshTickerGUI: refreshTickerGUI,
        setRefreshTickerGUI: setRefreshTickerGUI,
    };

    // Poll Workflow handling
    useEffect(() => {

        // Get poll data and proceed with edit poll entry.
        if (pollWorkflow === EnumPollWorkflow.editPoll_loading) {
            const fetchData = async () => {
                await getPollDataToEditPollEntry(tabContext, appContext, setPollWorkflow, setContextPollData);
            }

            fetchData();
        }

    }, [pollWorkflow]);

    return (
        <PollContext.Provider value={pollContext}>
            <TabContext.Provider value={tabContext}>
                <MainContainer botDialog bgNeutral>
                    <Flex column fillFlex gap="smaller" padding="medium" style={{ overflowX: 'hidden', overflowY: 'auto' }}>

                        {(pollWorkflow === EnumPollWorkflow.editPoll_loading) &&
                            <Flex column fillFlex justifyContent="center">
                                <Spinner />
                            </Flex>
                        }

                        {(pollWorkflow === EnumPollWorkflow.loading_not_found || pollWorkflow === EnumPollWorkflow.loading_no_creator || pollWorkflow === EnumPollWorkflow.loading_other_error) && <PollErrorLoading />}

                        {(pollWorkflow === EnumPollWorkflow.editPoll_entry || pollWorkflow === EnumPollWorkflow.close) && <PollCreateOrEdit />}
                        {(pollWorkflow === EnumPollWorkflow.editPoll_preview || pollWorkflow === EnumPollWorkflow.editPoll_preview_close) && <PollPreview messageId={messageId} />}
                    </Flex>
                </MainContainer>
            </TabContext.Provider>

        </PollContext.Provider>
    );
}

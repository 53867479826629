import { Button, Text, Spinner, Switch, MessageBar, MessageBarBody, tokens, TabList, Tab, TableColumnDefinition, createTableColumn, TableColumnSizingOptions, DataGrid, DataGridHeader, DataGridRow, DataGridHeaderCell, DataGridBody, DataGridCell, Title3 } from "@fluentui/react-components";
import { useContext, useEffect, useState } from "react";
import { EmptyFlexRow, Flex, FlexItem } from "sub/blocks/FlexBoxes";
import { TabContext, TabDialogType } from "sub/context/TabContext";
import { CartRegular, CheckmarkCircleFilled } from "@fluentui/react-icons";
import { backendEditPollClient, backendGetPollOrResultClient, backendTrackEvent } from "sub/library/epBackend";
import { AppContext, EnumAppEnvironment, getAppEnvironment } from "sub/context/AppContext";
import { EnumPolLView, PollDataClass, TypePollComment } from "sub/bot-client-shared/PollDataClass";
import { EnumCardEnvironment, Warning, checkEditPollResponse, cleanCardFromActions, createCardFromPollData } from "sub/bot-client-shared/adaptiveCards/botCardBuilder";
import { AdaptiveCardReact } from "sub/blocks/AdaptiveCardReact";
import { dialog_close_refresh, getSSOToken, link_purchase } from "sub/library/epCommon";
import { hyDebug } from "sub/library/hyDebug";


enum EnumViewPollTabMode {
    results = "results",
    comments = "comments"
}

// TabContext:
// This component is embedded in the dialog workflow from TabContext.

// This dialog is used to view a poll or a template.
export function ContentViewPoll() {
    const appContext = useContext(AppContext);
    const tabContext = useContext(TabContext);

    const [viewPollTabMode, setViewPollTabMode] = useState<EnumViewPollTabMode>(EnumViewPollTabMode.results);

    const [isLoading, setIsLoading] = useState(true);

    const [isRestricted, setIsRestricted] = useState(false);
    const [warningText, setWarningText] = useState("");
    const [cardPayload, setCardPayload] = useState<any>(null);

    const [showResultDetails, setShowResultDetails] = useState(appContext.viewSettings.session.viewResultWithDetails);

    // Get data from backend
    useEffect(() => {

        backendTrackEvent(appContext.appAuthData.lang, getAppEnvironment(appContext.environment.current), "Dialog.ViewPoll", "Details: " + showResultDetails + " | Tab: " + viewPollTabMode.toString(), appContext);

        const fetchData = async () => {
            try {
                const pollData = new PollDataClass();

                pollData.poll_guid = tabContext.activePollElement.current.poll_guid;
                pollData.poll_secret = tabContext.activePollElement.current.poll_secret;

                const requested_view = (tabContext.tabDialog === TabDialogType.ViewOrPostPoll ? (showResultDetails ? EnumPolLView.result_details : EnumPolLView.result) : EnumPolLView.poll);
                const sso_token = (appContext.isSSOEnabled) ? await getSSOToken(appContext.isSSOEnabled, appContext.setIsSSOEnabled, true) : null;
                const getPollResult = await backendGetPollOrResultClient(requested_view, true, appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, sso_token, appContext.appAuthData.displayName, appContext.appAuthData.lang, pollData.poll_guid, pollData.poll_secret);

                if (getPollResult.success) {
                    tabContext.activePollElement.current = getPollResult.pollData; // Update active poll element with full poll data.
                    const cardPayload = cleanCardFromActions(createCardFromPollData(EnumCardEnvironment.client, null, getPollResult.pollData, appContext.appAuthData.locale), true);
                    setCardPayload(cardPayload);
                    setIsRestricted(getPollResult.pollData.poll_results_restricted);

                }

                setWarningText(""); // Clear warning text

            } catch (ex: any) {
                hyDebug("Error: " + ex?.message);
                setWarningText("An unknown error occured. Please contact support.");
            }

            setIsLoading(false);
        };

        fetchData();
    }, [showResultDetails, viewPollTabMode]);

    // Define table for comments
    const tableColumns: TableColumnDefinition<TypePollComment>[] = [
        createTableColumn<TypePollComment>({
            columnId: "index",
            compare: (a, b) => (a.seq - b.seq),
            renderHeaderCell: () => (<b>#</b>),
            renderCell: (item) => item.seq,
        }),
        ...(tabContext.activePollElement.current.option_votes_are_anonymous ? [] : [
            createTableColumn<TypePollComment>({
                columnId: "name",
                compare: (a, b) => a.name.localeCompare(b.name),
                renderHeaderCell: () => (<b>Name</b>),
                renderCell: (item) => (<Text wrap>{item.name}</Text>),
            })
        ]),
        createTableColumn<TypePollComment>({
            columnId: "comment",
            compare: (a, b) => a.comment.localeCompare(b.comment),
            renderHeaderCell: () => (<b>Comments</b>),
            renderCell: (item: TypePollComment) => (<Text wrap>{item.comment}</Text>),
        }),
    ];

    // Define column sizing options for the ListPolls table (data grid).
    const tableColumnSizingOptions: TableColumnSizingOptions = {
        index: { idealWidth: 40 },
        created_on: { idealWidth: 80 },
        comment: { padding: 0 },
    };


    return (

        <>
            <Flex column gap="small" alignItems="center" justifyContent="flex-start" style={{ overflow: 'auto', minHeight: "60vh" }}>

                {/* Tab part */}
                {tabContext.tabDialog === TabDialogType.ViewOrPostPoll && tabContext.activePollElement.current.poll_view !== EnumPolLView.result_thankyou && (tabContext.activePollElement.current.option_voters_add_comments || tabContext.activePollElement.current.poll_comments_total > 0) && (
                    <TabList size="small" selectedValue={viewPollTabMode} onTabSelect={(event, data) => { setViewPollTabMode(data.value as EnumViewPollTabMode); }}>
                        <Tab value={EnumViewPollTabMode.results}>Results</Tab>
                        <Tab value={EnumViewPollTabMode.comments}>Comments</Tab>
                    </TabList>
                )}

                {/* Tab: Results: Info header with show details switch */}
                {viewPollTabMode === EnumViewPollTabMode.results && tabContext.tabDialog === TabDialogType.ViewOrPostPoll && (
                    <Flex gap="smaller" alignItems="center" fillWidth wrap>

                        {!isLoading && tabContext.activePollElement.current.option_results_creator_only && tabContext.activePollElement.current.user_is_creator && (
                            <>
                                <CheckmarkCircleFilled style={{ color: tokens.colorStatusSuccessForeground1 }} />
                                <Text weight="semibold" style={{ color: tokens.colorStatusSuccessForeground1 }} wrap>Creator only: Only you can see the poll results.</Text>
                            </>

                        )}

                        <FlexItem push >
                            <Switch
                                checked={showResultDetails}
                                label={"Show details"}
                                labelPosition="before"
                                onChange={() => { _toogleShowDetails(); }}
                            />
                        </FlexItem>

                    </Flex>
                )}

                {isLoading && (
                    <Spinner />
                )}

                {/* Tab: Results: Adaptive Card */}
                {viewPollTabMode === EnumViewPollTabMode.results && !isLoading && (
                    <div style={{ maxWidth: '80%', height: "80%", padding: "1.5em" }}>
                        <AdaptiveCardReact cardPayload={cardPayload} />
                    </div>
                )}

                {/* Tab: Comments: Table */}
                {viewPollTabMode === EnumViewPollTabMode.comments && (
                    <DataGrid
                        id={`ViewPollComments_${tabContext.refreshTickerGUI}` /* Use the GUI ticker to force a re-render */}
                        items={tabContext.activePollElement.current.comments}
                        columns={tableColumns}
                        sortable
                        getRowId={(item: TypePollComment) => item.seq}
                        focusMode="composite"
                        resizableColumns
                        resizableColumnsOptions={{ autoFitColumns: true }}
                        columnSizingOptions={tableColumnSizingOptions}
                    >
                        <DataGridHeader>
                            <DataGridRow>
                                {({ renderHeaderCell }) => (
                                    <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                )}
                            </DataGridRow>
                        </DataGridHeader>

                        <DataGridBody<TypePollComment>>
                            {({ item, rowId }) => (
                                <DataGridRow<TypePollComment> key={rowId}>
                                    {({ renderCell }) => (
                                        <DataGridCell>{renderCell(item)}</DataGridCell>
                                    )}
                                </DataGridRow>
                            )}
                        </DataGridBody>
                    </DataGrid>
                )}

                {/* Tab: Comments: Table */}
                {viewPollTabMode === EnumViewPollTabMode.comments && (
                    <FlexItem alignSelf="flex-start" column style={{ marginTop: "1.5em" }}>
                        {!tabContext.activePollElement.current.poll_results_restricted && tabContext.activePollElement.current.poll_comments_total === 0 && (
                            <Text>No comments yet.</Text>
                        )}

                        {tabContext.activePollElement.current.poll_results_restricted && (
                            <Text>-</Text>
                        )}
                    </FlexItem>
                )}
            </Flex>

            {!isLoading && warningText && (
                <MessageBar intent="warning">
                    <MessageBarBody>{warningText}</MessageBarBody>
                </MessageBar>
            )}

            {/* Button Footer */}
            <FlexItem column push>


                <Flex justifyContent="flex-end" gap="medium">
                    {tabContext.tabDialog === TabDialogType.ViewOrPostPoll && (<>
                        {isRestricted && (<Button appearance="secondary" onClick={() => { _unlockPoll(); }}>Reveal</Button>)}
                        {!isRestricted && (<Button appearance="secondary" onClick={() => { dialog_close_refresh(tabContext); }}>Close</Button>)}
                        {isRestricted && (<Button disabled={isLoading} appearance="primary" onClick={() => { _purchase_button(); }} icon={<CartRegular />}>Purchase license</Button>)}
                    </>)}

                    {tabContext.tabDialog === TabDialogType.ViewTemplate && (
                        <Button appearance="secondary" onClick={() => { dialog_close_refresh(tabContext); }}>Close</Button>
                    )}
                </Flex>
            </FlexItem>
        </>
    );

    async function _unlockPoll() {
        try {
            const pollData = new PollDataClass();

            pollData.poll_guid = tabContext.activePollElement.current.poll_guid;
            pollData.poll_secret = tabContext.activePollElement.current.poll_secret;

            const sso_token = (appContext.isSSOEnabled) ? await getSSOToken(appContext.isSSOEnabled, appContext.setIsSSOEnabled, true) : null;
            const getPollResult = await backendEditPollClient("unlock_results", appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, sso_token, appContext.appAuthData.lang, pollData.poll_guid, pollData.poll_secret);

            checkEditPollResponse(getPollResult);

            const cardPayload = cleanCardFromActions(createCardFromPollData(EnumCardEnvironment.client, null, getPollResult.pollData, appContext.appAuthData.locale), true);
            setCardPayload(cardPayload);

            setIsRestricted(getPollResult.pollData.poll_results_restricted);
            setWarningText(""); // Clear warning text

        } catch (ex: any) {
            if (ex instanceof Warning) {
                setWarningText(ex.message);
            } else {
                hyDebug("Error: " + ex?.message);
                setWarningText("An unknown error occured. Please contact support.");
            }
        }

        setIsLoading(false);
    }

    function _toogleShowDetails() {
        setIsLoading(true);
        setShowResultDetails(!showResultDetails);

        const newVal = !showResultDetails;

        appContext.viewSettings.session.viewResultWithDetails = newVal;
        appContext.updateViewSettings({ session: appContext.viewSettings.session })
    }

    function _purchase_button() {
        backendTrackEvent(appContext.appAuthData.lang, getAppEnvironment(appContext.environment.current), "Upgrade.Purchase", "", appContext);
        window.open(link_purchase, '_blank');
    }


}
import React from "react";
import { PollDataClass } from "../bot-client-shared/PollDataClass";

export enum EnumPollWorkflow {
    none,
    task_selector,
    
    loading_not_found,
    loading_no_creator,
    loading_other_error,
    
    close,

    // Edit poll + template handling
    editPoll_loading, // Also used for templates
    editPoll_entry, // Also used for templates
    editPoll_preview, // Also used for templates
    editPoll_preview_close, // Also used for templates

    // New + duplicate poll handling
    duplicatePoll_loading,
    newPoll_entry,

    // New + duplicate template handling
    duplicateTemplate_loading,
    newTemplate_entry,

    // Poll + template preview
    newPoll_preview, // Also used for templates
    newPoll_preview_close, // Also used for templates

    // Reuse poll handling
    reusePoll_loading, // only chat
    reusePoll_table, // only chat
    reusePoll_preview // only chat
}

export class PollContextClass {
    public pollData : PollDataClass = new PollDataClass();
    public updatePollData : (updates: Partial<PollDataClass>) => void = () => undefined;

    public pollWorkflow : EnumPollWorkflow = EnumPollWorkflow.none;
    public setPollWorkflow : React.Dispatch<React.SetStateAction<EnumPollWorkflow>>  = () => undefined;

}

export const PollContext = React.createContext<PollContextClass>(new PollContextClass());
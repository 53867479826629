import { Dialog, Button, DialogSurface, DialogTitle, DialogContent, DialogActions, DialogBody, Spinner, MessageBar, MessageBarBody, Text } from "@fluentui/react-components";
import { useContext, useEffect, useState } from "react";
import { EmptyFlexColumn, Flex, FlexItem } from "sub/blocks/FlexBoxes";
import { TabContext, TabDialogType } from "sub/context/TabContext";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { backendCreateEditVoteClient, backendGetPollOrResultClient, backendTrackEvent } from "sub/library/epBackend";
import { AppContext } from "sub/context/AppContext";
import { EnumPolLView, PollDataClass } from "sub/bot-client-shared/PollDataClass";
import { EnumCardEnvironment, Warning, checkEditVoteResponse, cleanCardFromActions, createCardFromPollData } from "sub/bot-client-shared/adaptiveCards/botCardBuilder";
import { AdaptiveCardReact } from "sub/blocks/AdaptiveCardReact";
import { dialog_close_refresh, getSSOToken } from "sub/library/epCommon";
import { hyDebug, hyDebugLocal } from "sub/library/hyDebug";
import { BotAppActionDataCreateVote } from "sub/bot-client-shared/BotAppActionDataCreateVote";

const actionAddAnswer = "pollAddAnswer";
const actionSubmitVote = "pollSubmitVote";

export function TabDialogEditVote() {
    const appContext = useContext(AppContext);
    const tabContext = useContext(TabContext);

    const [isLoading, setIsLoading] = useState(true);
    const [isWorking, setIsWorking] = useState(false);
    const [cardPayload, setCardPayload] = useState<any>(null);

    const [warningText, setWarningText] = useState("");

    useEffect(() => {
        backendTrackEvent(appContext.appAuthData.lang, "Tab", "Dialog.EditVote", "", appContext);

        const fetchData = async () => {
            const pollData = new PollDataClass();

            pollData.poll_guid = tabContext.activePollElement.current.poll_guid;
            pollData.poll_secret = tabContext.activePollElement.current.poll_secret;

            const view = EnumPolLView.poll;
            const sso_token = (appContext.isSSOEnabled) ? await getSSOToken(appContext.isSSOEnabled, appContext.setIsSSOEnabled, true) : null;
            const getPollResult = await backendGetPollOrResultClient(view, false, appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, sso_token, appContext.appAuthData.displayName, appContext.appAuthData.lang, pollData.poll_guid, pollData.poll_secret);

            if (getPollResult.success) {
                tabContext.activePollElement.current = getPollResult.pollData; // Update active poll element with full poll data.

                const cardPayload = cleanCardFromActions(createCardFromPollData(EnumCardEnvironment.client, null, getPollResult.pollData, appContext.appAuthData.locale), false);

                setCardPayload(cardPayload);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const onExecuteAction = (action: any) => {
        hyDebugLocal("Action executed: " + action?.id);
        if (action?.id === actionSubmitVote) {

            const fetchData = async () => {

                try {
                    setWarningText(""); // Clear warning text
                    setIsWorking(true);
                    const botActionDataCreateVote = new BotAppActionDataCreateVote(action?.data);
                    const sso_token = (appContext.isSSOEnabled) ? await getSSOToken(appContext.isSSOEnabled, appContext.setIsSSOEnabled, true) : null;

                    const voteResult = await backendCreateEditVoteClient("new", appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, sso_token, appContext.appAuthData.lang, botActionDataCreateVote.pollGuid, botActionDataCreateVote.pollSecret, botActionDataCreateVote.answerGuids, botActionDataCreateVote.comment, false);

                    checkEditVoteResponse(voteResult, action?.id);

                    // Switch to view poll dialog.
                    tabContext.setTabDialog(TabDialogType.ViewOrPostPoll);
                    setWarningText(""); // Clear warning text


                } catch (ex: any) {
                    if (ex instanceof Warning) {
                        setWarningText(ex.message);
                    } else {
                        hyDebug("Error: " + ex?.message);
                        setWarningText("An unknown error occured. Please contact support.");
                    }
                }
                setIsWorking(false);
            };

            fetchData();


        } else if (action?.id === actionAddAnswer) {
            // Switch to add answer dialog.
            tabContext.setTabDialog(TabDialogType.AddAnswer);
            setWarningText(""); // Clear warning text

        }
        hyDebugLocal("Data: " + action?.data); // Access the action data here
    };

    return (
        <Dialog defaultOpen onOpenChange={(event, data) => { if (!data.open) { dialog_close_refresh(tabContext); } }}>
            <DialogSurface>
                <DialogBody style={{ minHeight: "20em" }}>
                    <DialogTitle action={<Button appearance="subtle" aria-label="close" onClick={() => { dialog_close_refresh(tabContext); }} icon={<Dismiss24Regular />} />}>Vote</DialogTitle>
                    <DialogContent>
                        <Flex column fillFlex fillHeight gap="smaller" >
                            <Flex column gap="small" alignItems="center" style={{ overflow: 'auto' }}>
                                {isLoading && (<Spinner />)}

                                {!isLoading && (
                                    <div style={{ maxWidth: '80%', height: "80%", padding: "1.5em" }}>
                                        <AdaptiveCardReact cardPayload={cardPayload} funcOnExecuteAction={onExecuteAction} />
                                    </div>
                                )}
                            </Flex>

                            <FlexItem column push style={{ marginTop: "1em" }}>
                                <Flex fillFlex column gap="medium">
                                    {!isLoading && warningText && (
                                        <MessageBar intent="warning">
                                            <MessageBarBody>{warningText}</MessageBarBody>
                                        </MessageBar>
                                    )}

                                    <Flex gap="medium" alignItems="center">
                                        <Text>Please click "Submit" to save your vote.</Text>
                                        {isWorking && (<Spinner />)}
                                    </Flex>
                                </Flex>
                            </FlexItem>
                        </Flex>
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );


}
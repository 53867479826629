import { Dialog, Button, DialogSurface, DialogTitle, DialogContent, DialogBody, } from "@fluentui/react-components";
import { useContext } from "react";
import { TabContext, TabDialogType } from "sub/context/TabContext";
import { Dismiss24Regular, } from "@fluentui/react-icons";
import { dialog_close_refresh } from "sub/library/epCommon";

import { ContentViewPoll } from "./ContentViewPoll";
import { Flex } from "sub/blocks/FlexBoxes";

/// This dialog is used to view a poll or a template.
export function TabDialogViewPoll() {
    const tabContext = useContext(TabContext);

    return (
        <Dialog defaultOpen onOpenChange={(event, data) => { if (!data.open) { dialog_close_refresh(tabContext); } }}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle action={<Button appearance="subtle" aria-label="close" onClick={() => { dialog_close_refresh(tabContext); }} icon={<Dismiss24Regular />} />}>{(tabContext.tabDialog === TabDialogType.ViewOrPostPoll ? "View poll results" : "View template")}</DialogTitle>
                    <DialogContent>
                        <Flex column fillFlex fillHeight gap="small">
                            <ContentViewPoll />
                        </Flex>

                    </DialogContent>

                </DialogBody>
            </DialogSurface>
        </Dialog>
    );

}
import { empty_guid } from "./PollDataClass";

export class BotAppActionDataCreateVote {

    public pollGuid: string = empty_guid;
    public pollSecret: string = "";

    public answerGuids: string[] = [];
    public comment: string | undefined = undefined;

    constructor(data?: any) {
        if (data) {
            this.pollGuid = data.pollGuid ?? this.pollGuid;
            this.pollSecret = data.pollSecret ?? this.pollSecret;

            const answerString: string | undefined = data.selectedAnswers ?? undefined;
            this.answerGuids = (answerString) ? answerString.split(",") : [];

            this.comment = data.comment ?? this.comment;
        }
    }
}

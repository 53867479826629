import { mergeClasses, tokens } from "@fluentui/react-components";
import { AppContext } from "sub/context/AppContext";
import { useContext } from "react";
import { getBaseStyle } from "sub/library/epCommon";


export function MainContainer(props: { bgNeutral?: boolean, botDialog?: boolean, children: React.ReactNode }) {
    const appContext = useContext(AppContext);

    return (
        <div className={mergeClasses(getBaseStyle(appContext.themeString), (props?.botDialog ? "main-bot-dialog" : "main-container"))} style={{ backgroundColor: (props?.bgNeutral ? tokens.colorNeutralBackground3 : undefined) }}>
            {props.children}
        </div>
    );
}
import React from 'react';

// Define gap sizes
const gapSizes = {
  none: '0px',
  smaller: '4px',
  small: '8px',
  medium: '16px',
  large: '32px',
  larger: '64px',
};

// Define type for gap size
type GapSize = keyof typeof gapSizes;

// Update GridProps to include alignment options and default gap
type GridProps = {
  rows: number;
  columns: number;
  children: React.ReactNode[];
  gap?: GapSize; // 'none' as default
  wrap?: boolean; // New prop to enable wrapping
  style?: React.CSSProperties;
  justifyItems?: 'start' | 'end' | 'center' | 'stretch';
  justifyContent?: 'start' | 'end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  alignItems?: 'start' | 'end' | 'center' | 'stretch';
  fitContent?: boolean; // New prop for content-based column width
};

const Grid: React.FC<GridProps> = ({
  rows,
  columns,
  children,
  gap = 'none', // Default gap size
  style = {},
  justifyItems = 'stretch', // Default alignment: stretch to fill the cell
  justifyContent = 'start', // Default grid content alignment: start
  alignItems = 'stretch', // Default content alignment: stretch to fill the cell
  fitContent = false, // New prop for content-based column width
  wrap = false // Aktualisiert, um ein einfaches boolean zu sein
}) => {
  // Merge custom styles with the default styles
  const gridStyle: React.CSSProperties = {
    display: 'grid',
    gridTemplateRows: `repeat(${rows}, 1fr)`,
    gridTemplateColumns: fitContent ? `repeat(${columns}, auto)` : `repeat(${columns}, 1fr)`, // Conditionally set based on fitContent
    gap: gapSizes[gap],
    flexWrap: wrap ? 'wrap' : 'nowrap',
    justifyItems,
    justifyContent,
    alignItems,
    ...style,
  };

  return (
    <div style={gridStyle}>
      {children.map((item, index) => (
        <div key={index} style={{ border: '0px' }}>
          {item}
        </div>
      ))}
    </div>
  );
};

export default Grid;

import { Theme, teamsDarkTheme, teamsHighContrastTheme, teamsLightTheme } from "@fluentui/react-components";
import { TabContextClass, TabDialogType } from "sub/context/TabContext";
import { authentication, app as microsoftTeams } from "@microsoft/teams-js";
import { hyDebug, hyDebugLocal } from "./hyDebug";
import { AppContextClass, getAppEnvironment } from "sub/context/AppContext";
import { backendGetPollOrResultClient, backendTrackEvent } from "./epBackend";
import { EnumPolLView, PollDataClass, duplicatePoll } from "sub/bot-client-shared/PollDataClass";
import { EnumPollWorkflow } from "sub/context/PollContext";

export const environment = (window.location.hostname === "localhost" ? "local" : "azure");

export const app_name = "easypoll-client";
export const app_version = "2025-02-13";

// PROD
export const link_teams_app = "https://teams.microsoft.com/l/entity/312ac5ed-982b-453a-a784-f5258c182593";

// LOCAL+PROD
// export const link_teams_app = (environment === 'local') ? "https://teams.microsoft.com/l/entity/609f4500-69b7-436d-b546-5e7097b79af7" : "https://teams.microsoft.com/l/entity/312ac5ed-982b-453a-a784-f5258c182593";

export const link_teams_app_template = "https://teams.microsoft.com/l/entity/312ac5ed-982b-453a-a784-f5258c182593/index?&context=%7B%22subEntityId%22:%20%22template%22%7D";
export const link_purchase = "https://www.easy-poll.app/buy";

export function getBaseStyle(themeString: string): string {
  return (themeString === "default" ? "light" : themeString === "dark" ? "dark" : "contrast");
}

export function getTheme(themeString: string): Theme {
  if (themeString === "dark") { return teamsDarkTheme; }
  if (themeString === "contrast") { return teamsHighContrastTheme; }

  return teamsLightTheme;
}


export function hyDate(paramDate: string | Date): Date {
  try {
    // If paramDate is not a string, than it is already a date object.
    if (typeof paramDate !== "string") { return new Date(paramDate); }

    // Split the date string into its components
    const parts = paramDate.split('-').map(part => parseInt(part, 10));

    // Create a new Date object using the local timezone
    // Note: The month argument is 0-indexed, so subtract 1 to get the correct month
    return new Date(parts[0], parts[1] - 1, parts[2]);
  } catch (Ex) {
    hyDate("Error: Date object could not be created for '" + paramDate + "'");
    return new Date();
  }
}

export function transformDateToDayMonthYear(locale: string, date: Date): string {
  return date.toLocaleDateString((locale) ? locale : 'default', { day: '2-digit', month: '2-digit', year: 'numeric' });
}

export const refreshTicker = (prevValue: number) => {
  if (prevValue >= 10) {
    return 1; // reset to 1 if value has reached 10
  }
  return prevValue + 1;
};

export function dialog_close_refresh(tabContext: TabContextClass, appContext?: AppContextClass | undefined) {
  if (appContext) {
    // If appContext is set, update auth data as well.
    appContext.setAuthRefreshTicker(refreshTicker);
  }
  tabContext.setRefreshTickerData(refreshTicker);

  tabContext.setTabDialog(TabDialogType.None);
}

export function dialog_close_reload(appContext: AppContextClass) {
  // Unnötig: tabContext.setTabDialog(TabDialogType.None);
  reload_without_deeplink(appContext);
}

export function reload_without_deeplink(appContext: AppContextClass) {
  try {
    if (appContext.appDeepLink.current) {
      hyDebugLocal("Clear deep link in reload");
      microsoftTeams.openLink(link_teams_app);
    } else {
      hyDebugLocal("Normal reload");
      window.location.reload();
    }
  } catch (ex: any) {
    hyDebug("Error in reload_without_deeplink: " + ex?.message);
    window.location.reload();
  }

}

export async function getSSOToken(isSSOEnabled: boolean, setIsSSOEnabled: (val: boolean) => void, askSilent: boolean = true): Promise<string> {
  try {
    if (microsoftTeams.isInitialized() === false) { await microsoftTeams.initialize(); }

    let token = "";
    if (askSilent) {
      token = await authentication.getAuthToken({ claims: [], silent: askSilent });
    } else {
      token = await authentication.getAuthToken();
    }

    if (isSSOEnabled && !token) {
      // SSO is enabled but no token was returned. Disable SSO.
      setIsSSOEnabled(false);
    }

    if (!isSSOEnabled && token) {
      // SSO is not enabled but a token was returned. Enable SSO.
      setIsSSOEnabled(true);
    }

    return token;
  } catch (ex) {
    hyDebug("Error getting sso token: " + ex);

    if (isSSOEnabled) {
      // SSO is enabled but no token was returned. Disable SSO.
      setIsSSOEnabled(false);
    }

    return "";
  }
}

export function GenerateGUID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
}

export function getNullSubstring(input: string | null | undefined, start: number, length: number): string {
  try {
    if (input === null || input === undefined) {
      return "";
    }

    if (start >= input.length) {
      return "";
    }

    return input.substring(start, length);

  } catch (ex: any) {

    hyDebug("getNullSubstring: " + ex?.message);
    return "";
  }
}


export async function getPollDataToNewPollEntry(target_is_template: boolean, tabContext: TabContextClass, appContext: AppContextClass, setPollWorkflow: (param: EnumPollWorkflow) => void, setPollData: (param: PollDataClass) => void) {
  try {
    hyDebugLocal("Fetch Data for Duplicate Poll");

    if (tabContext.activePollElement.current.poll_guid && tabContext.activePollElement.current.poll_secret) {

      // Get full poll data including answer options.
      const sso_token = (appContext.isSSOEnabled) ? await getSSOToken(appContext.isSSOEnabled, appContext.setIsSSOEnabled, true) : null;
      const pollResults = await backendGetPollOrResultClient(EnumPolLView.poll, false, appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, sso_token, appContext.appAuthData.displayName, appContext.appAuthData.lang, tabContext.activePollElement.current.poll_guid, tabContext.activePollElement.current.poll_secret);

      if (pollResults.success) {
        // Duplicate poll data and set it as new poll data.
        setPollData(duplicatePoll(target_is_template, pollResults.pollData, appContext.appAuthData.hasActiveLicense));
      }
    }
  } catch (ex) {
    hyDebug("Duplicate Poll failed: " + ex);
  }

  const action = (target_is_template ? EnumPollWorkflow.newTemplate_entry : EnumPollWorkflow.newPoll_entry);
  setPollWorkflow(action);

}

export async function getPollDataToEditPollEntry(tabContext: TabContextClass, appContext: AppContextClass, setPollWorkflow: (param: EnumPollWorkflow) => void, setPollData: (param: PollDataClass) => void) {
  // Error handling is stricter than in getPollDataToNewPollEntry.
  // Reason: If the poll is not found, the user should not be able to edit it.

  const err_not_found = "Edit Poll: Poll not found.";
  const err_no_creator = "Edit Poll: User is not creator.";

  try {
    hyDebugLocal("Fetch Data for Edit Poll");

    if (tabContext.activePollElement.current.poll_guid && tabContext.activePollElement.current.poll_secret) {

      // Get full poll data including answer options and voting numbers.
      const sso_token = (appContext.isSSOEnabled) ? await getSSOToken(appContext.isSSOEnabled, appContext.setIsSSOEnabled, true) : null;
      const pollResults = await backendGetPollOrResultClient(EnumPolLView.result, false, appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, sso_token, appContext.appAuthData.displayName, appContext.appAuthData.lang, tabContext.activePollElement.current.poll_guid, tabContext.activePollElement.current.poll_secret);

      if (pollResults.success) {
        if (pollResults.pollData.poll_view === EnumPolLView.not_found) { throw new Error(err_not_found); }
        if (!pollResults.pollData.user_is_creator) { throw new Error(err_no_creator); }

        // Reset poll_view
        pollResults.pollData.poll_view = EnumPolLView.poll;

        setPollData(pollResults.pollData);
        setPollWorkflow(EnumPollWorkflow.editPoll_entry);
      } else {
        throw new Error("Edit Poll: Poll data could not be loaded (poll: " + tabContext.activePollElement.current.poll_guid + ").");
      }
    }
  } catch (ex: any) {
    hyDebug("Edit Poll loading failed: " + ex);

    // Track error
    const category = getAppEnvironment(appContext.environment.current);
    backendTrackEvent(appContext.appAuthData.lang, category, "Poll.ErrorLoading", "Reason: " + ex?.message, appContext);

    if (ex?.message === err_not_found) {
      setPollWorkflow(EnumPollWorkflow.loading_not_found);
    }
    else if (ex?.message === err_no_creator) {
      setPollWorkflow(EnumPollWorkflow.loading_no_creator);
    }
    else {
      setPollWorkflow(EnumPollWorkflow.loading_other_error);
    }
  }

}

export function getRetryWaitingTime(attempt: number) {

  // First run will be 1000 ms.
  // After that, it grows exponentially with a random jitter of 30%.
  // Base for exponential backoff is 500 ms.

  // Starting time
  const baseTime = 500;

  // Exponential backoff
  const delay = baseTime + (baseTime * Math.pow(2, attempt - 1));

  // Includes random jitter up to 30% of the calculated delay
  const jitter = Math.floor(Math.random() * delay * 0.3);

  return delay + jitter;
}
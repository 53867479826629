import { Button, Text, Spinner, MessageBar, MessageBarBody, Title3, Label, Input, InputOnChangeData } from "@fluentui/react-components";
import { AddSquareRegular, ChevronDownRegular, ChevronRightRegular, DeleteRegular, InfoFilled } from "@fluentui/react-icons";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { EmptyFlexRow, Flex, FlexItem } from "sub/blocks/FlexBoxes";
import { TabContext, TabDialogType } from "sub/context/TabContext";
import { backendEditPollClient, backendGetPollOrResultClient, backendTrackEvent, GetCurrentOffset } from "sub/library/epBackend";
import { AppContext, EnumAppEnvironment, getAppEnvironment } from "sub/context/AppContext";
import { empty_guid, EnumPolLView, PollDataClass, TypePollAnswer } from "sub/bot-client-shared/PollDataClass";
import { app_name, app_version, getSSOToken } from "sub/library/epCommon";
import { max_answer_count, max_answer_length } from "./PollCreateOrEdit";
import { AnswerActionMenu } from "./MiscAnswerActionMenu";
import { dialog as teamsDialog } from "@microsoft/teams-js";
import { BotTaskSubmitData } from "sub/bot-client-shared/BotTaskSubmitData";
import { hyDebug } from "sub/library/hyDebug";

const err_other = "Error adding the new answers. Please try again or contact support at support@easy-poll.app.";
const err_no_new_answer = "Please enter at least one new answer.";
const err_max_answers = "You have reached the maximum number of 100 answers. Please remove some answers before adding more.";

const react_app_id = process.env.REACT_APP_CLIENT_ID;

// TabContext:
// This component is embedded in the dialog workflow from TabContext.

// This dialog is used to add an custom answer to a poll.
export function ContentAddAnswer(props: { messageId: string | undefined }) {
    const appContext = useContext(AppContext);
    const tabContext = useContext(TabContext);

    const [isLoading, setIsLoading] = useState(true);
    const [isWorking, setisWorking] = useState(false);
    const [warningText, setWarningText] = useState("");

    const [pollData, setPollData] = useState(new PollDataClass(tabContext.activePollElement.current));

    const [showExistingAnswers, setShowExistingAnswers] = useState(false);
    const toggleExistingAnswers = () => {
        setShowExistingAnswers(!showExistingAnswers);
    };

    const [listNewAnswers, setListNewAnswers] = useState<TypePollAnswer[]>([{ seq: 1, answer_guid: empty_guid, text: "", pre_select: false, answer_votes: 0, voter_names: "" }]);
    const [calendarLastDate, setCalendarLastDate] = useState<Date | undefined>(undefined);

    // Load data:
    useEffect(() => {
        backendTrackEvent(appContext.appAuthData.lang, getAppEnvironment(appContext.environment.current), "Dialog.AddAnswer", "", appContext);

        const fetchData = async () => {

            const view = EnumPolLView.poll;
            const sso_token = (appContext.isSSOEnabled) ? await getSSOToken(appContext.isSSOEnabled, appContext.setIsSSOEnabled, true) : null;
            const getPollResult = await backendGetPollOrResultClient(view, false, appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, sso_token, appContext.appAuthData.displayName, appContext.appAuthData.lang, pollData.poll_guid, pollData.poll_secret);

            if (getPollResult.success) {
                setPollData(getPollResult.pollData);
                setIsLoading(false);
            } else {
                setWarningText("Error loading poll data. Please contact support at support@easy-poll.app.");
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            {isLoading && (
                <Flex column fillFlex justifyContent="center">
                    <Spinner />
                </Flex>
            )}


            {!isLoading && (<>
                {/* Main Content */}
                < Flex column gap="small" style={{ overflow: 'auto', paddingRight: "0.5em" }}>
                    <Text wrap><Text weight="semibold">Question:</Text> {pollData.question}</Text>

                    <Flex gap="smaller" alignItems="center" wrap>
                        {showExistingAnswers ? (<ChevronDownRegular onClick={toggleExistingAnswers} style={{ cursor: "pointer" }} />) : (<ChevronRightRegular onClick={toggleExistingAnswers} style={{ cursor: "pointer" }} />)}
                        <Text onClick={toggleExistingAnswers} style={{ cursor: "pointer" }} weight="regular">Existing answers ({pollData.answers.length})</Text>
                    </Flex>

                    {showExistingAnswers && (
                        <>
                            <Text>These answers are already in the poll:</Text>
                            {pollData.answers.map((answer, index) => (
                                <Flex key={answer.seq} gap="small" alignItems="center">
                                    <Label>{`Answer ${index + 1}:`}</Label>
                                    <Input readOnly disabled style={{ flexGrow: 1 }} value={answer.text} />
                                </Flex>
                            ))}
                        </>
                    )}

                    <EmptyFlexRow size="1.5" />

                    <Text weight="semibold">New answers</Text>
                    <Text>Add your custom answers to the poll below:</Text>
                    {listNewAnswers.map((answer, index) => (
                        <Flex key={answer.seq} gap="small" alignItems="center">
                            <Label>{`Answer ${pollData.answers.length + index + 1}:`}</Label>
                            <Input readOnly={isWorking} placeholder={`Enter your answer ${pollData.answers.length + index + 1}`} style={{ flexGrow: 1 }} value={answer.text} maxLength={max_answer_length} onChange={(ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => { __setAnswerText(answer.seq, data.value, false); }} contentAfter={<AnswerActionMenu newText={(val: string, append: boolean) => { __setAnswerText(answer.seq, val, append); }} anchor="answer" calendarLastDate={calendarLastDate} setCalendarLastDate={setCalendarLastDate} />} />
                            <Button disabled={(index === 0)} icon={<DeleteRegular />} onClick={() => __removeAnswer(answer.seq)} />
                        </Flex>
                    ))}
                    <FlexItem push><Button disabled={isWorking} icon={<AddSquareRegular />} onClick={__addAnswer}>More</Button></FlexItem>
                    <EmptyFlexRow />
                </Flex>
            </>
            )}

            {warningText && (
                <>
                    <MessageBar intent="error">
                        <MessageBarBody>{warningText}</MessageBarBody>
                    </MessageBar>
                    <EmptyFlexRow size="0.5" />
                </>
            )}

            {/* Button Footer */}
            <FlexItem column push>

                <Flex gap="smaller" alignItems="center"><InfoFilled /><Text wrap>Please check your custom answers before submit. Once added, only the poll creator can remove the answers again.</Text></Flex>

                <EmptyFlexRow size="1.0" />

                <Flex justifyContent="flex-end" gap="medium">
                    {isWorking && (<Spinner />)}
                    <Button disabled={isWorking || isLoading} onClick={() => { __onClose(); }}>Discard</Button>
                    <Button appearance="primary" disabled={isWorking || isLoading} onClick={() => { __submit() }}>Submit</Button>
                </Flex>
            </FlexItem>
        </>
    );

    function __addAnswer() {

        setWarningText("");

        // Find the next seq by adding 1 to the current highest seq
        const nextId = Math.max(...listNewAnswers.map(a => a.seq)) + 1;

        // Check for answer maximum count.
        if (nextId > max_answer_count) {
            setWarningText(`Maximum number of answers (${max_answer_count}) reached.`);
            return;
        }

        const newAnswer: TypePollAnswer = { seq: nextId, answer_guid: empty_guid, text: "", pre_select: false, answer_votes: 0, voter_names: "" };
        setListNewAnswers([...listNewAnswers, newAnswer]);
    };

    function __removeAnswer(seq: number) {
        setWarningText("");
        setListNewAnswers(listNewAnswers.filter(answer => answer.seq !== seq));
    };

    function __setAnswerText(seq: number, val: string, append: boolean) {
        setListNewAnswers(
            listNewAnswers.map(answer => {
                if (answer.seq === seq) {
                    const text = (append) ? answer.text + val : val;
                    return { ...answer, text };
                }
                return answer;
            })
        );
    };

    function __onClose() {
        if (appContext.environment.current === EnumAppEnvironment.message_chat) {
            // Submit will automatically close the dialog
            teamsDialog.url.submit();
        } else if (appContext.environment.current === EnumAppEnvironment.personal_tab) {
            tabContext.setTabDialog(TabDialogType.None);
        } else {
            hyDebug("PollPreview: Unknown poll purpose");
        }
    }


    async function __submit() {
        try {

            // Basic checks
            setisWorking(true);
            setWarningText("");

            // Check if there is at least one answer
            if (listNewAnswers.length === 1 && listNewAnswers[0].text.trim() === "") {
                setWarningText(err_no_new_answer);
                setisWorking(false);
                return;
            }

            // Check that the total number of answers does not exceed 100
            if (pollData.answers.length + listNewAnswers.length > 100) {
                setWarningText(err_max_answers);
                setisWorking(false);
                return;
            }

            // Copy pollData class
            const newPollData = new PollDataClass(pollData);

            // Override answers in Poll Data with new answers to reduce payload size.
            // Remove empty answers (filter) and renumber ids (map):
            newPollData.answers = listNewAnswers.filter(answer => answer.text.trim() !== '').map((answer, index) => ({
                ...answer,
                seq: index + 1, // Start seq from 1
            }));

            if (appContext.environment.current === EnumAppEnvironment.message_chat) {

                const sso_token = (appContext.isSSOEnabled) ? await getSSOToken(appContext.isSSOEnabled, appContext.setIsSSOEnabled, true) : "";
                const botData: BotTaskSubmitData = {
                    userUPN: appContext.appAuthData.userUPN,
                    userID: appContext.appAuthData.userID,
                    userToken: appContext.appAuthData.userToken,
                    userSSO: sso_token,
                    userLang: appContext.appAuthData.lang,
                    userLocale: appContext.appAuthData.locale,

                    app_name: app_name,
                    app_version: app_version,
                    utc_offset: GetCurrentOffset(),

                    task: "add_answers",
                    messageId: props.messageId,
                    pollExists: true,
                    pollData: newPollData
                }

                // Submit will automatically close the dialog
                teamsDialog.url.submit(botData, react_app_id!);
            } else if (appContext.environment.current === EnumAppEnvironment.personal_tab) {

                // Call backend to add the new answers
                const sso_token = (appContext.isSSOEnabled) ? await getSSOToken(appContext.isSSOEnabled, appContext.setIsSSOEnabled, true) : null;
                const updatedPoll = await backendEditPollClient("add_answers", appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, sso_token, appContext.appAuthData.lang, newPollData.poll_guid, newPollData.poll_secret, newPollData);

                if (updatedPoll.success) {
                    setWarningText(""); // Clear warning text
                    setisWorking(false);
                    tabContext.setTabDialog(TabDialogType.EditVote);

                    tabContext.activePollElement.current = updatedPoll.pollData; // Update active poll element with full poll data.
                    // No need to refresh GUI as we switch directly to the EditVote dialog.
                } else {
                    setWarningText("Error adding the new answers. Please try again or contact support at support@easy-poll.app.");
                    setisWorking(false);
                }

            } else {
                hyDebug("PollPreview: Unknown poll purpose");
            }

        } catch (err: any) {
            setWarningText(err_other);
            setisWorking(false);
            hyDebug(err);
        }
    }

}
// Chat GPT generated.

import * as React from 'react';
import { ChangeEvent, useContext, useRef, useState } from 'react';
import { InfoLabel, Input, InputOnChangeData, Label, Radio, Switch, Text, tokens } from '@fluentui/react-components';
import { ChevronDownRegular, ChevronRightRegular } from '@fluentui/react-icons';
import { EmptyFlexRow, Flex } from '../blocks/FlexBoxes';
import Segment from 'sub/blocks/Segment';
import { PollContext } from 'sub/context/PollContext';
import { hyDebug } from 'sub/library/hyDebug';
import { AppContext } from 'sub/context/AppContext';
import { link_purchase } from 'sub/library/epCommon';
import { PopupFreePlanLimit } from './MiscAllOther';
import Grid from 'sub/blocks/Grid';

// PollContext:
// This component is embedded in the pollworkflow

export function PollOptions(props: { editMode: boolean }) {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const appContext = useContext(AppContext);
    const pollContext = useContext(PollContext);

    const [showBuyPopup, setShowBuyPopup] = useState(false);
    const refButtonPost = useRef<HTMLInputElement>(null);

    const disableOptionInEditMode = !pollContext.pollData.is_template && props.editMode;

    return (
        <Flex column gap="smaller">
            {showBuyPopup && (
                <PopupFreePlanLimit appContext={appContext} situation="pro_option" refElement={refButtonPost} fShowPopup={setShowBuyPopup} />
            )}

            <Text wrap>
                {isOpen && (
                    <>
                        <ChevronDownRegular onClick={toggleOpen} style={{ cursor: "pointer", verticalAlign: "middle" }} />
                        <Text onClick={toggleOpen} style={{ cursor: "pointer" }} weight='bold'>Options: </Text>
                    </>
                )}

                {!isOpen && (
                    <>
                        <ChevronRightRegular onClick={toggleOpen} style={{ cursor: "pointer", verticalAlign: "middle" }} />
                        <Text onClick={toggleOpen} style={{ cursor: "pointer" }} weight='bold'>Options: </Text>
                        <Text style={{ color: tokens.colorNeutralForeground2 }}>
                            {!pollContext.pollData.option_votes_have_limit ? "Multiple answers per vote" : ("Max. " + pollContext.pollData.option_votes_max_limit + (pollContext.pollData.option_votes_max_limit === 1 ? " answer per vote" : " answers per vote"))}
                            {pollContext.pollData.option_votes_are_anonymous ? " | Anonymous voting" : " | Non-anonymous voting"}
                            {pollContext.pollData.option_results_creator_only ? " | Results for creator only" : " | Results for all voters"}
                            {pollContext.pollData.option_voters_add_comments ? " | Include comments" : ""}
                            {pollContext.pollData.option_voters_add_answers ? " | Custom Answers" : ""}
                        </Text>
                    </>
                )
                }
            </Text>

            {isOpen && (
                <Segment fillWidth>
                    <Grid columns={2} rows={6} gap="smaller" fitContent alignItems='center' wrap>

                        {/* Poll limits */}
                        <Text weight='semibold'><InfoLabel label={"Answers per vote"} info={"Specifies the maximum number of answers a user can select per vote. If you select multiple answers, the user can select any up to all answers."} />:</Text>
                        <Flex wrap>
                            <Radio disabled={disableOptionInEditMode} checked={pollContext.pollData.option_votes_have_limit === true} onClick={() => { __setOptionVotesHaveLimit(true); }}
                                label={
                                    <Flex gap="small" alignItems='center' wrap>
                                        <Label onClick={() => { __setOptionVotesHaveLimit(true); }} style={{ cursor: "pointer" }}>Max:</Label>
                                        <Input
                                            disabled={disableOptionInEditMode}
                                            onClick={() => { __setOptionVotesHaveLimit(true); }} type="number" min={1}
                                            onChange={(ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => { __onOptionAnswerMaxLimitChange(ev, data); }} size="small" style={{ width: "5em" }} value={pollContext.pollData.option_votes_max_limit.toString()}
                                        />
                                    </Flex>
                                }

                                style={{ alignItems: 'center' }}
                            />

                            <Radio disabled={disableOptionInEditMode} checked={pollContext.pollData.option_votes_have_limit === false} onClick={() => { __setOptionVotesHaveLimit(false); }} label="Multiple answers" />
                        </Flex>


                        {/* Anonymous */}
                        <Text weight='semibold'><InfoLabel label={"Names"} info={"Specifies if voting is anonymous. By default, names are shown."} />:</Text>
                        <Switch label="Votes are anonymous" disabled={disableOptionInEditMode && pollContext.pollData.option_votes_are_anonymous} checked={pollContext.pollData.option_votes_are_anonymous} onChange={(event) => { __setOptionVotesAreAnonymous(event.target.checked); }} />

                        {/* Pro features */}
                        <Text ref={refButtonPost} weight='bold' style={{ color: tokens.colorNeutralForeground2BrandHover }} >Pro features</Text>
                        <Text></Text>

                        <Text weight='semibold'><InfoLabel label="Results" info="By default, everyone can see the results of a poll after voting. With this option activated, only the creator can see the results." />:</Text>
                        <Switch label="Visible only for creator" disabled={disableOptionInEditMode && pollContext.pollData.option_results_creator_only} checked={pollContext.pollData.option_results_creator_only} onChange={(event) => { __setOptionResultsForCreatorOnly(event.target.checked); }} />

                        <Text weight='semibold'><InfoLabel label="Include comments" info="When enabled, voters can include a comment with their vote. By default, all comments are visible to everyone. To restrict visibility to only the creator, select 'Visible only to creator.'" />:</Text>
                        <Switch label="Allow voters to include a comment" checked={pollContext.pollData.option_voters_add_comments} onChange={(event) => { __setOptionVotersAddComments(event.target.checked); }} />

                        <Text weight='semibold'><InfoLabel label="Custom answers" info="If enabled, voters can add their own answers to the poll." />:</Text>
                        <Switch label="Allow voters to add answers" checked={pollContext.pollData.option_voters_add_answers} onChange={(event) => { __setOptionVotersAddAnswers(event.target.checked); }} />

                    </Grid>
                </Segment>
            )}
            <EmptyFlexRow />
        </Flex>
    );

    function __setOptionVotesHaveLimit(val: boolean) {
        pollContext.updatePollData({ option_votes_have_limit: val });
    }

    function __onOptionAnswerMaxLimitChange(ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        try {
            pollContext.updatePollData({ option_votes_max_limit: Number(data.value) });
        } catch (ex) {
            hyDebug("Error setting limit: " + ex);
            pollContext.updatePollData({ option_votes_max_limit: 1 });
        }
    }

    function __setOptionVotesAreAnonymous(val: boolean) {
        pollContext.updatePollData({ option_votes_are_anonymous: val });
    }

    function __setOptionResultsForCreatorOnly(val: boolean) {
        if (appContext.appAuthData.hasActiveLicense) {
            pollContext.updatePollData({ option_results_creator_only: val });
        } else {
            // Open webpage to buy license via browser
            pollContext.updatePollData({ option_results_creator_only: false });
            if (val) {setShowBuyPopup(true)};

        }
    }

    function __setOptionVotersAddAnswers(val: boolean) {
        if (appContext.appAuthData.hasActiveLicense) {
            pollContext.updatePollData({ option_voters_add_answers: val });
        } else {
            // Open webpage to buy license via browser
            pollContext.updatePollData({ option_voters_add_answers: false });
            if (val) {setShowBuyPopup(true)};
        }
    }

    function __setOptionVotersAddComments(val: boolean) {
        if (appContext.appAuthData.hasActiveLicense) {
            pollContext.updatePollData({ option_voters_add_comments: val });
        } else {
            // Open webpage to buy license via browser
            pollContext.updatePollData({ option_voters_add_comments: false });
            if (val) {setShowBuyPopup(true)};
        }
    }


};
